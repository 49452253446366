<template lang="pug">
  base-page
    template(v-slot:titlu)
      span Dashboard
    el-row(:gutter="12" )
        el-col(:md="24")
            el-card(style='margin-top:25px')
                div(slot="header" class="clearfix")
                    span Ziua curenta
                    a(class='el-button el-tooltip el-button--success el-button--small is-circle' target='_blank' :href="get_excel_href()") 
                        i.el-icon-document
                div
                    el-progress( :text-inside="true" :stroke-width="18" :percentage="Info.ProcentZiuaCurenta" )
                    p Ziua {{Info.ZiuaCurentaLucratoare}} din {{Info.TotalZileLucratoare}} de zile lucratoare
    
        el-col(:md="24")
            el-card(style='margin-top:25px')
                div(slot='header' class='clearfix')
                    span Prezenti astazi
                div
                    ul.lista-prezenti( v-if="Info.Prezenti.length > 0")
                        li(v-for='p in Info.Prezenti') 
                            span.nume {{p.Nume}}
                            span.ora {{p.Prezenta.OraIntrare}}
                    div(v-else)
                        span Nu aveti angajati prezenti astazi
        
        el-col(:md="24" v-if="Info.Intarziati.length > 0")
            el-card(style='margin-top:25px')
                div(slot='header' class='clearfix')
                    span Au intarziat astazi
                div
                    ul.lista-prezenti
                        li(v-for='p in Info.Intarziati') 
                            span.nume {{p.Nume}}
                            span.ora {{p.Intarziere.Durata}}
           

</template>

<script>
import settings from "../backend/LocalSettings";
import BasePage from "../pages/BasePage";
import TitluPagina from '../widgets/TitluPagina';
import Chart from 'chart.js';

export default {
  name: "dashboard",
  extends: BasePage,
  components: {
      'base-page': BasePage,
      'titlu-pagina': TitluPagina
  },
  data () {
    return { 
      Info: { 
          TotalZileLucratoare: 0, 
          ZiuaCurentaLucratoare: 0,
          ProcentZiuaCurenta: 0, 
          Prezenti: [],
          Intarziati: []
      },
      Filters: {}
    }
  },
  methods: 
  {
    async get_info(){
        this.refresh_info();
    },

    async refresh_info() {
        this.select_menu_item('dashboard');
        var response = await this.post("dashboard/refresh_info" );
        this.Info    = response;
        console.log({Info: this.Info})
    },
    get_excel_href(){
        var newFilters = this.Filters;
        newFilters['token'   ] = settings.get_token();
        newFilters['id_firma'] = settings.get_id_firma();

        console.log(newFilters)
        console.log({PrezentiAstazi: this.Info.Prezenti})

        var encoded = encodeURIComponent( JSON.stringify( newFilters ) );

        return `${settings.get_base_url()}dashboard/excel?Filters=${encoded}`;
    }
  },
  mounted()
  {
    settings.verify_login_and_redirect(this);
    this.refresh_info();
  }
};
</script>

<style lang="less" scoped>
    .my-card{
        min-height: 450px;
    }
    .lista-prezenti{
        padding:0;
        width: 100%;
        column-count: 1;
        @media(min-width: 768px){
            column-count: 3;
        }
        @media(min-width: 1200px){
            column-count: 5;
        }
        flex-direction: column;
        flex-wrap: wrap;
        li{
            display: flex;
            flex-direction: row;
            justify-content: space-between;   
            .nume{
                display: inline-flex;
            }
            .ora{
                display: inline-flex;
                border-right: 1px solid lightgray;
                padding-right: 5px;
            }
        }
    }
    
</style>
